/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Subtitle, TitleMain, Image, Button, Title, Text, YouTube, SeparateLine, SeparateLineWrap, Form, Fullmap, FullmapWrap, FullmapCover } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <Column className="css-1nn8wsi --style3 --full pb--30 pt--30" name={"hotel-uvod"} parallax={false} fullscreen={true} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/3319/94f44333b1e249198b349f0517daf2e8_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/3319/94f44333b1e249198b349f0517daf2e8_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/3319/94f44333b1e249198b349f0517daf2e8_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/3319/94f44333b1e249198b349f0517daf2e8_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/3319/94f44333b1e249198b349f0517daf2e8_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/3319/94f44333b1e249198b349f0517daf2e8_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/3319/94f44333b1e249198b349f0517daf2e8_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/3319/94f44333b1e249198b349f0517daf2e8_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--bottom" columns={"1"}>
            
            <ColumnWrapper className="--style2 --center pb--80" style={{"maxWidth":478,"marginBottom":46.078125,"paddingBottom":null}}>
              
              <Subtitle className="subtitle-box fs--36 subtitle-box--invert mt--02" content={"<span style=\"color: rgb(63, 30, 218);\">Apartmány</span>"}>
              </Subtitle>

              <TitleMain className="title-box fs--72 title-box--invert ls--01 mt--0" style={{"maxWidth":500,"marginTop":null}} content={"<span style=\"color: rgb(62, 26, 235);\">BATELOV</span>"}>
              </TitleMain>

              <Image className="--invert" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/o/g/Ikonky/Hotel/hotel-34.svg"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":120}} srcSet={""} svgColor={"rgba(255,255,255,1)"} RootClassName={"column__pic"}>
              </Image>

              <Button className="btn-box btn-box--style4" href={"https://www.supersaas.cz/schedule/ubytovani_blazkova/ubytov%C3%A1n%C3%AD"} style={{"maxWidth":378,"marginBottom":0,"paddingBottom":11.0859375}} content={"REZERVACE"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"yrxznq77z9"}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Title className="title-box fs--26" content={"<span style=\"text-decoration-line: underline;\">Nabídka</span>"}>
              </Title>

              <Text className="text-box text-box--center fs--22" content={"<span style=\"color: var(--black);\">Pro zákazníky, kteří mají objednávku přes booking nabízíme slevu 10%. Podmínky sdělíme jako odpověď na dotaz z našeho mailu ubytovani.blazkova@gmail.com</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="js-anim  --anim4 --anim-s2 bg--top pb--40 pt--40" anim={"4"} name={"sluzby"} animS={"2"} style={{"paddingTop":null,"paddingBottom":null}} layout={"l3"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Subtitle className="subtitle-box" content={"PRO NAŠE ZÁKAZNÍKY MÁME K DISPOZICI"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":700}} content={"<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--1 flex--stretch" columns={"1"}>
            
            <ColumnWrapper className="--style2">
              
              <Image src={"https://cdn.swbpg.com/t/3319/3a54013dca454d54a7a4534eddfdf5e9_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":80}} srcSet={"https://cdn.swbpg.com/t/3319/3a54013dca454d54a7a4534eddfdf5e9_s=350x_.jpg 350w, https://cdn.swbpg.com/t/3319/3a54013dca454d54a7a4534eddfdf5e9_s=660x_.jpg 660w, https://cdn.swbpg.com/t/3319/3a54013dca454d54a7a4534eddfdf5e9_s=860x_.jpg 860w"} RootClassName={"column__icon"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/3319/39a472d372de4dbfb64e0b36cf7868af_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":80}} srcSet={"https://cdn.swbpg.com/t/3319/39a472d372de4dbfb64e0b36cf7868af_s=350x_.jpg 350w, https://cdn.swbpg.com/t/3319/39a472d372de4dbfb64e0b36cf7868af_s=660x_.jpg 660w, https://cdn.swbpg.com/t/3319/39a472d372de4dbfb64e0b36cf7868af_s=860x_.jpg 860w"} RootClassName={"column__icon"}>
              </Image>

              <Subtitle className="subtitle-box mt--0" style={{"maxWidth":"","marginTop":null}} content={"BAZÉN S INFRASAUNOU A SOLNÁ JESKYNĚ V CENĚ UBYTOVÁNÍ"}>
              </Subtitle>

              <Text className="text-box fs--20" content={"<span style=\"color: rgb(2, 1, 10);\">Vyhřívaný bazén se slanou vodou s teplotou 28 - 30 stupňů je k dispozici kdykoliv při ubytování na tři a více nocí<br>Při ubytování na tři a více nocí je v ceně jedna návštěva solné jeskyně.<br></span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--style2">
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/o/g/Ikonky/Hotel/hotel-13.svg"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":80}} srcSet={""} svgColor={"rgba(0,0,0,1)"} RootClassName={"column__icon"}>
              </Image>

              <Subtitle className="subtitle-box mt--0" style={{"maxWidth":"","marginTop":null}} content={"ELEKTRONIKA NA POKOJI"}>
              </Subtitle>

              <Text className="text-box fs--20" content={"<span style=\"color: rgb(67, 66, 69);\">Na pokoji je Smart televizor s příjmem tv signálu přes internet&nbsp; a bezplatná WiFi síť.<br><br></span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--style2">
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/o/g/Ikonky/Hotel/hotel-14.svg"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":80}} srcSet={""} svgColor={"rgba(0,0,0,1)"} RootClassName={"column__icon"}>
              </Image>

              <Subtitle className="subtitle-box mt--0" style={{"maxWidth":"","marginTop":null}} content={"KLÍČ&nbsp;"}>
              </Subtitle>

              <Text className="text-box fs--20 w--900" content={"<span style=\"color: rgb(0, 0, 0);\">Klíč Vám předá majitel ubytování. Jelikož zde není recepce, je nutné zatelefonovat na čísla<br>&nbsp;777 869 757 nebo 606 625 351<br>&nbsp;asi 20 minut před příjezdem.&nbsp;<br><br></span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--l8w js-anim  --anim3 pb--30 pt--30" anim={"3"} name={"apartman_1"} lightbox={true}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Image alt={"exteriér "} src={"https://cdn.swbpg.com/t/3319/7140a8703d514fdc88c8002bb436bdcd_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/3319/7140a8703d514fdc88c8002bb436bdcd_s=350x_.jpg 350w, https://cdn.swbpg.com/t/3319/7140a8703d514fdc88c8002bb436bdcd_s=660x_.jpg 660w, https://cdn.swbpg.com/t/3319/7140a8703d514fdc88c8002bb436bdcd_s=860x_.jpg 860w, https://cdn.swbpg.com/t/3319/7140a8703d514fdc88c8002bb436bdcd_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/3319/7140a8703d514fdc88c8002bb436bdcd_s=2000x_.jpg 2000w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/3319/d4720c05401a4c0e95595321c56af8fa_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/3319/d4720c05401a4c0e95595321c56af8fa_s=350x_.jpg 350w, https://cdn.swbpg.com/t/3319/d4720c05401a4c0e95595321c56af8fa_s=660x_.jpg 660w, https://cdn.swbpg.com/t/3319/d4720c05401a4c0e95595321c56af8fa_s=860x_.jpg 860w, https://cdn.swbpg.com/t/3319/d4720c05401a4c0e95595321c56af8fa_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/3319/d4720c05401a4c0e95595321c56af8fa_s=2000x_.jpg 2000w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/3319/f655fbf25202401e8f6ae0e2ba3f4a23_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/3319/f655fbf25202401e8f6ae0e2ba3f4a23_s=350x_.jpg 350w, https://cdn.swbpg.com/t/3319/f655fbf25202401e8f6ae0e2ba3f4a23_s=660x_.jpg 660w, https://cdn.swbpg.com/t/3319/f655fbf25202401e8f6ae0e2ba3f4a23_s=860x_.jpg 860w, https://cdn.swbpg.com/t/3319/f655fbf25202401e8f6ae0e2ba3f4a23_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/3319/f655fbf25202401e8f6ae0e2ba3f4a23_s=2000x_.jpg 2000w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/3319/232cf6713e8b4ab280b3ecbb12477745_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/3319/232cf6713e8b4ab280b3ecbb12477745_s=350x_.jpg 350w, https://cdn.swbpg.com/t/3319/232cf6713e8b4ab280b3ecbb12477745_s=660x_.jpg 660w, https://cdn.swbpg.com/t/3319/232cf6713e8b4ab280b3ecbb12477745_s=860x_.jpg 860w, https://cdn.swbpg.com/t/3319/232cf6713e8b4ab280b3ecbb12477745_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/3319/232cf6713e8b4ab280b3ecbb12477745_s=2000x_.jpg 2000w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/3319/c0714431eb5f4c0198b3c9070b33a9f5_s=860x_.jpg"} svg={false} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/3319/c0714431eb5f4c0198b3c9070b33a9f5_s=350x_.jpg 350w, https://cdn.swbpg.com/t/3319/c0714431eb5f4c0198b3c9070b33a9f5_s=660x_.jpg 660w, https://cdn.swbpg.com/t/3319/c0714431eb5f4c0198b3c9070b33a9f5_s=860x_.jpg 860w, https://cdn.swbpg.com/t/3319/c0714431eb5f4c0198b3c9070b33a9f5_s=1400x_.jpg 1400w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/3319/a849d4c1dabf44d7948c7fa3e97d2d13_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/3319/a849d4c1dabf44d7948c7fa3e97d2d13_s=350x_.jpg 350w, https://cdn.swbpg.com/t/3319/a849d4c1dabf44d7948c7fa3e97d2d13_s=660x_.jpg 660w, https://cdn.swbpg.com/t/3319/a849d4c1dabf44d7948c7fa3e97d2d13_s=860x_.jpg 860w, https://cdn.swbpg.com/t/3319/a849d4c1dabf44d7948c7fa3e97d2d13_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/3319/a849d4c1dabf44d7948c7fa3e97d2d13_s=2000x_.jpg 2000w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--l8w pb--30 pt--30" name={"apartman_2"} lightbox={true}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/3319/958dad16f85a4ab9894beb729ef1e062_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/3319/958dad16f85a4ab9894beb729ef1e062_s=350x_.jpg 350w, https://cdn.swbpg.com/t/3319/958dad16f85a4ab9894beb729ef1e062_s=660x_.jpg 660w, https://cdn.swbpg.com/t/3319/958dad16f85a4ab9894beb729ef1e062_s=860x_.jpg 860w, https://cdn.swbpg.com/t/3319/958dad16f85a4ab9894beb729ef1e062_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/3319/958dad16f85a4ab9894beb729ef1e062_s=2000x_.jpg 2000w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/3319/a8e62399da9e48a890ca07fff86a9948_s=860x_.jpg"} svg={false} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/3319/a8e62399da9e48a890ca07fff86a9948_s=350x_.jpg 350w, https://cdn.swbpg.com/t/3319/a8e62399da9e48a890ca07fff86a9948_s=660x_.jpg 660w, https://cdn.swbpg.com/t/3319/a8e62399da9e48a890ca07fff86a9948_s=860x_.jpg 860w, https://cdn.swbpg.com/t/3319/a8e62399da9e48a890ca07fff86a9948_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/3319/a8e62399da9e48a890ca07fff86a9948_s=2000x_.jpg 2000w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/3319/1602073c83f543cdb50e15cb1356dc40_s=860x_.jpg"} svg={false} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/3319/1602073c83f543cdb50e15cb1356dc40_s=350x_.jpg 350w, https://cdn.swbpg.com/t/3319/1602073c83f543cdb50e15cb1356dc40_s=660x_.jpg 660w, https://cdn.swbpg.com/t/3319/1602073c83f543cdb50e15cb1356dc40_s=860x_.jpg 860w, https://cdn.swbpg.com/t/3319/1602073c83f543cdb50e15cb1356dc40_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/3319/1602073c83f543cdb50e15cb1356dc40_s=2000x_.jpg 2000w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/3319/a7c45c53102041a0aa5c89a189a72f42_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/3319/a7c45c53102041a0aa5c89a189a72f42_s=350x_.jpg 350w, https://cdn.swbpg.com/t/3319/a7c45c53102041a0aa5c89a189a72f42_s=660x_.jpg 660w, https://cdn.swbpg.com/t/3319/a7c45c53102041a0aa5c89a189a72f42_s=860x_.jpg 860w, https://cdn.swbpg.com/t/3319/a7c45c53102041a0aa5c89a189a72f42_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/3319/a7c45c53102041a0aa5c89a189a72f42_s=2000x_.jpg 2000w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/3319/e549c61a4f294705a24659478bd04e53_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/3319/e549c61a4f294705a24659478bd04e53_s=350x_.jpg 350w, https://cdn.swbpg.com/t/3319/e549c61a4f294705a24659478bd04e53_s=660x_.jpg 660w, https://cdn.swbpg.com/t/3319/e549c61a4f294705a24659478bd04e53_s=860x_.jpg 860w, https://cdn.swbpg.com/t/3319/e549c61a4f294705a24659478bd04e53_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/3319/e549c61a4f294705a24659478bd04e53_s=2000x_.jpg 2000w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/3319/64dcb17cb7f1494ebae7ceb3aa77cb0e_s=860x_.jpg"} svg={false} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/3319/64dcb17cb7f1494ebae7ceb3aa77cb0e_s=350x_.jpg 350w, https://cdn.swbpg.com/t/3319/64dcb17cb7f1494ebae7ceb3aa77cb0e_s=660x_.jpg 660w, https://cdn.swbpg.com/t/3319/64dcb17cb7f1494ebae7ceb3aa77cb0e_s=860x_.jpg 860w, https://cdn.swbpg.com/t/3319/64dcb17cb7f1494ebae7ceb3aa77cb0e_s=1400x_.jpg 1400w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--l8w pb--30 pt--30" name={"ostatni"} lightbox={true}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/3319/c7584c25628a4a5889a04308192e86e0_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/3319/c7584c25628a4a5889a04308192e86e0_s=350x_.jpg 350w, https://cdn.swbpg.com/t/3319/c7584c25628a4a5889a04308192e86e0_s=660x_.jpg 660w, https://cdn.swbpg.com/t/3319/c7584c25628a4a5889a04308192e86e0_s=860x_.jpg 860w, https://cdn.swbpg.com/t/3319/c7584c25628a4a5889a04308192e86e0_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/3319/c7584c25628a4a5889a04308192e86e0_s=2000x_.jpg 2000w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/3319/6468a471f27a41cea11a57ddd7bb605c_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/3319/6468a471f27a41cea11a57ddd7bb605c_s=350x_.jpg 350w, https://cdn.swbpg.com/t/3319/6468a471f27a41cea11a57ddd7bb605c_s=660x_.jpg 660w, https://cdn.swbpg.com/t/3319/6468a471f27a41cea11a57ddd7bb605c_s=860x_.jpg 860w, https://cdn.swbpg.com/t/3319/6468a471f27a41cea11a57ddd7bb605c_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/3319/6468a471f27a41cea11a57ddd7bb605c_s=2000x_.jpg 2000w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/3319/d6c111295cfe4b08a95a5cb028370429_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/3319/d6c111295cfe4b08a95a5cb028370429_s=350x_.jpg 350w, https://cdn.swbpg.com/t/3319/d6c111295cfe4b08a95a5cb028370429_s=660x_.jpg 660w, https://cdn.swbpg.com/t/3319/d6c111295cfe4b08a95a5cb028370429_s=860x_.jpg 860w, https://cdn.swbpg.com/t/3319/d6c111295cfe4b08a95a5cb028370429_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/3319/d6c111295cfe4b08a95a5cb028370429_s=2000x_.jpg 2000w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/3319/e8b9b2e8651f404f841285f2c04887c4_s=860x_.png"} svg={false} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/3319/e8b9b2e8651f404f841285f2c04887c4_s=350x_.png 350w, https://cdn.swbpg.com/t/3319/e8b9b2e8651f404f841285f2c04887c4_s=660x_.png 660w, https://cdn.swbpg.com/t/3319/e8b9b2e8651f404f841285f2c04887c4_s=860x_.png 860w, https://cdn.swbpg.com/t/3319/e8b9b2e8651f404f841285f2c04887c4_s=1400x_.png 1400w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/3319/81eddddcc74441ce8dab0351409154d9_s=350x_.png"} svg={false} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/3319/81eddddcc74441ce8dab0351409154d9_s=350x_.png 350w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/3319/b03cafc9da814a3d83e08b0e282c8fd6_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/3319/b03cafc9da814a3d83e08b0e282c8fd6_s=350x_.jpg 350w, https://cdn.swbpg.com/t/3319/b03cafc9da814a3d83e08b0e282c8fd6_s=660x_.jpg 660w, https://cdn.swbpg.com/t/3319/b03cafc9da814a3d83e08b0e282c8fd6_s=860x_.jpg 860w, https://cdn.swbpg.com/t/3319/b03cafc9da814a3d83e08b0e282c8fd6_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/3319/b03cafc9da814a3d83e08b0e282c8fd6_s=2000x_.jpg 2000w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"aw6s03l72q5"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <YouTube embedId={"ihcpujM4j00"} autoplay={false}>
              </YouTube>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--40 pt--40" name={"nabidka-pokoju"} style={{"paddingTop":null,"paddingBottom":null,"backgroundColor":"rgba(242, 242, 242, 1)"}} layout={"l10"} lightbox={true}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Subtitle className="subtitle-box" content={"NABÍDKA APARTMÁNŮ<br>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":450}} content={"Celková cena závisí na počtu nocí a počtu osob. Cena a záloha bude upřesněna po dotazu na mail."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--2 flex--top" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/3319/f5b3a4a8e7f7460dbdfc09bd1cca5895_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"marginTop":null}} srcSet={"https://cdn.swbpg.com/t/3319/f5b3a4a8e7f7460dbdfc09bd1cca5895_s=350x_.jpg 350w, https://cdn.swbpg.com/t/3319/f5b3a4a8e7f7460dbdfc09bd1cca5895_s=660x_.jpg 660w, https://cdn.swbpg.com/t/3319/f5b3a4a8e7f7460dbdfc09bd1cca5895_s=860x_.jpg 860w, https://cdn.swbpg.com/t/3319/f5b3a4a8e7f7460dbdfc09bd1cca5895_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/3319/f5b3a4a8e7f7460dbdfc09bd1cca5895_s=2000x_.jpg 2000w"} description={"ložnice apartmán 1"} RootClassName={"column__pic"}>
              </Image>

              <Subtitle className="subtitle-box" style={{"maxWidth":""}} content={"APARTMÁN 1<br><br>"}>
              </Subtitle>

              <Text className="text-box fs--18" content={"<span style=\"color: rgb(6, 6, 6);\">V oddělené ložnici je jedna manželské postel a dvě postele samostatné, v podkroví čtyři další čtyři postele. V obývacím pokoji je plně vybavený kuchyňský kout. Koupelna se sprchovým koutem a oddělené WC.<br>Z pokoje i ložnice přístup na terasu.<br></span>"}>
              </Text>

              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-variable-1);\"><br>od 690 Kč</span>&nbsp;osoba/ NOC<br>minimální počet nocí 3<br>minimální počet osob 2<br>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/3319/b9c706d43a5a4466ae97d676d7d74954_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"marginTop":null}} srcSet={"https://cdn.swbpg.com/t/3319/b9c706d43a5a4466ae97d676d7d74954_s=350x_.jpg 350w, https://cdn.swbpg.com/t/3319/b9c706d43a5a4466ae97d676d7d74954_s=660x_.jpg 660w, https://cdn.swbpg.com/t/3319/b9c706d43a5a4466ae97d676d7d74954_s=860x_.jpg 860w, https://cdn.swbpg.com/t/3319/b9c706d43a5a4466ae97d676d7d74954_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/3319/b9c706d43a5a4466ae97d676d7d74954_s=2000x_.jpg 2000w"} RootClassName={"column__pic"}>
              </Image>

              <Subtitle className="subtitle-box" style={{"maxWidth":""}} content={"APARTMÁN 2<br><br>"}>
              </Subtitle>

              <Text className="text-box fs--18" content={"<span style=\"color: rgb(11, 11, 11);\">V oddělené ložnici je jedna manželské postel a jedna postel samostatná. V obývacím pokoji je možnost další postele a dále je zde plně vybavený kuchyňský kout. Koupelna se sprchovým koutem a oddělené WC.</span>"}>
              </Text>

              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-variable-1);\">od 600 Kč</span>&nbsp;osoba/ NOC<br>minimální počet nocí 3<br>minimální počet osob 2<br>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"kontakt"} style={{"marginTop":null,"backgroundColor":"rgba(18, 18, 18, 1)"}} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Subtitle className="subtitle-box" style={{"maxWidth":""}} content={"<span style=\"color: rgb(210, 180, 60);\">APARTMÁNY<br></span><span style=\"color: rgb(200, 181, 44);\">BATELOV</span><br>"}>
              </Subtitle>

              <Text className="text-box text-box--invert" content={"APARTMÁNY BATELOV<br>Třešťská 55<br>Batelov&nbsp; &nbsp; 588 51<br>Česká republika"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Subtitle className="subtitle-box subtitle-box--invert" style={{"maxWidth":200}} content={"CENA JIŽ OD 550 KČ za osobu a noc<br>závisí na počtu nocí a obsazenosti<br>"}>
              </Subtitle>

              <Button className="btn-box btn-box--style1" href={"https://www.supersaas.cz/schedule/ubytovani_blazkova/ubytov%C3%A1n%C3%AD"} content={"REZERVACE"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Subtitle className="subtitle-box pl--0 pr--0" style={{"maxWidth":260,"paddingLeft":null,"paddingRight":null}} content={"<span style=\"color: var(--color-variable-1);\">DOTAZY & REZERVACE</span>"}>
              </Subtitle>

              <Subtitle className="subtitle-box fs--18 subtitle-box--invert mt--08 pl--0 pr--0" style={{"maxWidth":363,"marginTop":null,"paddingLeft":null,"paddingRight":null}} content={"apartmanbatelov@seznam.cz<br><br>+420 777 869 757"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"delici-prvek"} layout={"l6"}>
          
          <SeparateLineWrap className="--full" style={{"backgroundColor":"rgba(242, 242, 242, 1)"}}>
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="pb--30 pt--30" name={"aktivity-v-okoli"} layout={"l13"}>
          
          <ColumnWrap className="column__flex el--2" columns={"2"}>
            
            <ColumnWrapper className="--center">
              
              <Image src={"https://cdn.swbpg.com/t/3319/03b3d8192c8f4a278bb3e9eec3ba061a_s=860x_.jpg"} svg={false} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":480}} srcSet={"https://cdn.swbpg.com/t/3319/03b3d8192c8f4a278bb3e9eec3ba061a_s=350x_.jpg 350w, https://cdn.swbpg.com/t/3319/03b3d8192c8f4a278bb3e9eec3ba061a_s=660x_.jpg 660w, https://cdn.swbpg.com/t/3319/03b3d8192c8f4a278bb3e9eec3ba061a_s=860x_.jpg 860w, https://cdn.swbpg.com/t/3319/03b3d8192c8f4a278bb3e9eec3ba061a_s=1400x_.jpg 1400w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--center">
              
              <Subtitle className="subtitle-box" style={{"maxWidth":260}} content={"AKTIVITY V OKOLÍ HOTELU"}>
              </Subtitle>

              <Text className="text-box text-box--center fs--20 w--900" style={{"maxWidth":536,"marginBottom":124,"paddingBottom":75.515625}} content={"<span style=\"color: rgb(0, 0, 0);\">Batelov je malé městečko na Vysočině, ve kterém v současnosti žije přibližně 2200 obyvatel. Spojení s okolím zajišťuje pravidelná vlaková a autobusová doprava. V obci se nachází zámek, který prošel opravou, je v soukromém vlastnictví a není přístupný veřejnosti. Zámecký park, který je přístupný, je udržován a láká ke klidným procházkám a relaxaci při pohledu na místní rybník.&nbsp;<br>Je zde zachovalá část židovského gheta, synagoga a místní židovský hřbitov. V Batelově pobýval K.H.Borovský, který zde má pamětní desku. Batelovem&nbsp; a okolím vede naučná stezka, která připomíná jeho zdejší pobyt.<br>Z blízkého okolí se vyplatí navštívit hrad Roštejn, hrad a zámek Žirovnice, zřícenina hradu Štamberk, Telč se zámkem a nejkrásnějším náměstím kde se točily pohádky a v neposlední řadě ZOO Jihlava.<br>Pro lyžaře je v blízkosti skiareál Mrákotín, Křemešník a Šacberk u Jihlavy</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"delici-prvek2"} layout={"l6"}>
          
          <SeparateLineWrap className="--full" style={{"backgroundColor":"rgba(242, 242, 242, 1)"}}>
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="pb--30 pt--30" name={"rezervace"} style={{"backgroundColor":"rgba(242, 242, 242, 1)"}}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper className="--center">
              
              <Subtitle className="subtitle-box" content={"REZERVACE"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":650}} content={"<span style=\"color: rgb(15, 15, 16);\">Máte dotaz? Potřebujete poradit? Nebo jen nám chcete něco sdělit? Napište nám pomocí kontaktního formuláře - my se Vám zpětně ozveme.</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper className="--center">
              
              <Form action={"/contact"} fields={[{"name":"Celé jméno","type":"text","required":true,"placeholder":"Zadejte celé jméno"},{"name":"Kontaktní e-mail","type":"email","required":true,"placeholder":"@"},{"name":"Telefonní číslo","type":"text","required":true,"placeholder":"+420"},{"name":"Den příjezdu (od 14:00)","type":"date"},{"name":"Den odjezdu (do 11:00)","type":"date"},{"name":"ODESLAT","type":"submit"}]} layout={"l3"}>
              </Form>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Fullmap className="--style2" name={"mapa"}>
          
          <FullmapWrap className="--full">
            
            <FullmapCover zoom={"16"} place={"Batelov"}>
            </FullmapCover>

          </FullmapWrap>

        </Fullmap>


        <Column className="pb--25 pt--25" name={"paticka"} style={{"paddingTop":null,"paddingBottom":null,"backgroundColor":"var(--color-variable-2)"}}>
          
          <ColumnWrap className="column__flex el--3 flex--top" style={{"maxWidth":1300}} columns={"3"}>
            
            <ColumnWrapper className="--left">
              
              <Text className="text-box fs--16 text-box--invert" content={"<a href=\"#hotel-uvod\">ÚVOD</a>"}>
              </Text>

              <Text className="text-box fs--16 mt--02" style={{"marginTop":null}} content={"<a href=\"#sluzby\">SLUŽBY</a>"}>
              </Text>

              <Text className="text-box fs--16 mt--02" style={{"marginTop":null}} content={"<a href=\"#nabidka-pokoju\">POKOJE</a>"}>
              </Text>

              <Text className="text-box fs--16 mt--02" style={{"marginTop":null}} content={"<a href=\"#recenze-zakazniku\">RECENZE</a>"}>
              </Text>

              <Text className="text-box fs--16 mt--02" style={{"marginTop":null}} content={"<a href=\"#galerie\">FOTOGALERIE</a>"}>
              </Text>

              <Text className="text-box fs--16 mt--02" style={{"marginTop":null}} content={"<a href=\"#aktivity-v-okoli\">AKTIVITY V OKOLÍ</a>"}>
              </Text>

              <Text className="text-box fs--16 mt--02" style={{"marginTop":null}} content={"<a href=\"#rezervace\">REZERVACE/KONTAKT</a>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center">
              
              <TitleMain className="title-box title-box--style1 title-box--invert" style={{"marginBottom":33,"paddingBottom":17.0546875}} content={"APARTMÁNY"}>
              </TitleMain>

              <TitleMain className="title-box fs--26 title-box--invert mt--0" style={{"marginTop":null}} content={"BATELOV"}>
              </TitleMain>

              <Text className="text-box text-box--invert" content={"<br>"}>
              </Text>

              <Text className="text-box" content={"<span style=\"color: rgb(103, 103, 103);\">Klikněte a začněte psát text, tento prostor je určený pro text.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right">
              
              <Subtitle className="subtitle-box subtitle-box--center fs--18 subtitle-box--invert ls--001" content={"<span style=\"color: var(--color-variable-1);\">KONTAKT</span>"}>
              </Subtitle>

              <Text className="text-box text-box--center fs--18 text-box--invert lh--12" content={"Marie Blažková<br>Havlíčkova 357<br>58901 Třešť<br>IČO 42630371&nbsp; &nbsp; &nbsp; DIČ 525521150<br>"}>
              </Text>

              <Text className="text-box text-box--center fs--18 text-box--invert lh--12" content={"apartmanbatelov@seznam.cz<br>+420 777 869 757"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}